
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.account-my-templates-tab {
  .additional-documents-item {
    background: $color-white;
  }
}
